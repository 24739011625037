/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false , // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Pratik's Portfolio",
  description:
    "PhD in Computer Science at FIU. I like creating utility based tools on the side to automate tasks like deploying this website with a 3 letter trigger word ",
  og: {
    title: "Pratik Poudel Portfolio",
    type: "website",
    url: "https://powerpratik.github.io",
  },
};

//Home Page
const greeting = {
  title: "Pratik Poudel",
  logo_name: "Pratik",
  nickname: "PP ",
  subTitle:
    "I like creating utility based tools on the side to automate tasks like deploying this website with a 3 letter trigger word ",
  resumeLink:
    "https://drive.google.com/file/d/1ICUQOp1Py719RZ6aUnVLJtacH90rwVtJ/view?usp=sharing",
  portfolio_repository: "https://github.com/powerpratik/masterPortfolio",
  githubProfile: "https://github.com/powerpratik",
};

const socialMediaLinks = [
  
  {
    name: "Github",
    link: "https://github.com/powerpratik",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/pratik-poudel-/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UChSHHRV7ddv7SPbXYEe0ZbQ",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:ppoud0018@fiu.edu",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/pratik__poudel",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/powerpratik/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/powerpratik/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⁍ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⁍ Experience of working with Computer Vision and NLP projects",
        "⁍ Experience in working in trend analysis of current trends like  covid analysis in its inception",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⁍ Building responsive website front end using React-Redux",
        "⁍ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⁍ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },

        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⁍ Experience working on multiple cloud platforms",
        "⁍ Hosting and maintaining websites on virtual machine instances along with integration of databases",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        
      ],
    },
    {
      title: "Utility Based Tools For Automation",
      fileName: "FullStackImg",
      skills: [
        "⁍ I like building utility based tool to automate my daily workflow like",
        "⁍ Creating scripts to automatically use the recording of a zoom meeting or url audio to create a summarized transcript to refresh and highlight key points addressed by people on a meeting",
        "⁍ Creating a bash script to deploy this very website coupled with Raycast with a simple trigger word from anywhere on my laptop",
        "⁍ Creating a tool to automatically transfer files from my lab PC to my laptop and vice versa",
      ],
      softwareSkills: [
        
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Bash Scripting",
          fontAwesomeClassname: "simple-icons:square-terminal",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Raycast",
          fontAwesomeClassname: "simple-icons:magnifying-glass",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },

        
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "#",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "#",
    },
    
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "#",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Florida International University ",
      subtitle: "PhD. in Computer Science*",
      logo_path: "fiu_logo.png",
      alt_name: "Florida International University ",
      duration: "2020 - Current",
      descriptions: [
        "⁍ I have cleared multiple courses related to Storage Systems, Algorithms,  Artificial Intelligence, Graph Machine Learning , Data Mining, Visualization etc.",
        "⁍ I am currently a research assistant. As part of it, I have worked on distributed systems focusing on caching algorithms using a inhouse simulator developed in my lab- simulus.",
        "⁍ My interest align towards storage systems simulation and modelling. I am keen towards leveraging ML in storage systems- exploring possibility of LLMS in the storage system domain.",
        "⁍ I am also a part of executive board member of the organization Nepalese Student at FIU.",
      ],
      website_link: "https://www.fiu.edu/",
    },
    {
      title: "Kathmandu University",
      subtitle: "B.E. in Computer Engineering",
      logo_path: "ku_logo.png",
      alt_name: "Kathmandu University",
      duration: "2015 - 2019",
      descriptions: [
        "⁍ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, ML and AI, Software Development Lifecycle etc.",
        "⁍ Apart from this, I was in the organizing committee of one of the biggest IT event in Nepal organized on a University level",
        "⁍ Finalist in semester project competition hosted by Yco- Nepal based software company, with Nepali Braille Project- A arduino/ android based hardware project for the visually impaired to assist in learning and interfacing using android application and Braille implementation in arduino.   ",
      ],
      website_link: "https://www.ku.edu.np",
    },
    
  ],
};

const certifications = {
  certifications: [
    {
      title: "Udacity Data Scientist ",
      subtitle: "- Udacity",
      logo_path: "udacity_logo.png",
      certificate_link:
        "https://graduation.udacity.com/confirm/5KUKG646",
      alt_name: "Udacity",
      color_code: "#8C151599",
    },
    {
      title: "Python for Data Science",
      subtitle: "- Kirill Erremenko",
      logo_path: "udemy_logo.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-feaec545-4ac8-4d23-b1cb-4bf0be5f134e/",
      alt_name: "datascience",
      color_code: "#00000099",
    },
    {
      title: "Digital Marketing Google Digital Garage ",
      subtitle: "- Google Digital Garage ",
      logo_path: "google_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1B3IMh7pELsS8dSPl4z5qHgncygjtTiSP/view?usp=share_link",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Intro to Python ",
      subtitle: "- Datacamp",
      logo_path: "datacamp_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1IMEddCHZ3Kv98gj2DIGj99BynuUwXIxj/view?usp=share_link",
      alt_name: "Datacamp",
      color_code: "#1F70C199",
    },
    {
      title: "Intermediate Python ",
      subtitle: "- Datacamp",
      logo_path: "datacamp_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1VlUPidbDHLmyXj26UpSUPRQdLlR-EdyE/view?usp=share_link ",
      alt_name: "Datacamp",
      color_code: "#1F70C199",
    },
    
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked as software developer in various emerging startups and also as a research assistant in the field of storage systems.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Research Assistant at ModLab and Sylab -FIU",
          company: "Florida International University- Modlab",
          company_url: "https://people.cis.fiu.edu/liux/",
          logo_path: "fiu_logo.png",
          duration: "August 2020 - Current",
          location: "Miami, Florida",
          description:
            "I am working on unified caching solutions for distributed storage systems with Sylab team leveraging the in house simulator(simulus) built in Modlab at FIU. ",
          color: "#0879bf",
        },
       
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Plan Analytics Intern",
          company: "Deerwalk Institute of Technology.",
          company_url: "https://deerwalk.edu.np/DWIT/",
          logo_path: "dwit_logo.jpeg",
          duration: "July - September, 2019",
          location: "Kathmandu, Nepal",
          description:
            "Worked as an Intern on a major product focused in visualization of data and providing actionable insights using tools like charts, graphs etc",
          color: "#000000",
        },
        
      ],
    },
    {
      title: "Volunteering",
      experiences: [
        {
          title: "One week program on Software Development ",
          company: "Fusemachines",
          company_url: "https://fusemachines.com",
          logo_path: "fuse_machines.png",
          duration: "2015",
          location: "Miami, Florida",
          description:
            "Got a chance to explore the software development cycle in the orgranization. From the integration of the front end tools(like ember js), db tools(like mongo db) and backend(like node) , I got a chance to learn about how a software production system worked in tandem with various entities",
          color: "#4285F4",
        },
        
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects/Publications",
  description:
    " I am involved in interdisciplinary technological projects that encompass the evaluation and application of advanced computational methods, machine learning models for communication accessibility, educational programming tools, and assistive devices for the visually impaired.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "10.1145/3465332.3470884",
      name: "A Scalable Framework for Heterogeneous Environmental Data Management Using Smart Data Pipeline",
      createdAt: "2021-07-01T16:26:54Z",
      description: "Published paper in PEARC 25 ",
      url:
        "",
    },
    {
      id: "10.1145/3465332.3470884",
      name: "RAPTOR: Reconfigurable Advanced Platform for Transdisciplinary Open Research",
      createdAt: "2021-07-01T16:26:54Z",
      description: "Published paper in PEARC 25 ",
      url:
        "",
    },
    {
      id: "10.1145/3465332.3470884",
      name: "Unifying the data center caching layer: Feasible? profitable?",
      createdAt: "2021-07-01T16:26:54Z",
      description: "Published paper in hotstorage conference- 2021 ",
      url:
        "https://dl.acm.org/doi/10.1145/3465332.3470884",
    },
    {
      id: "10.1145/3573900.3593632",
      name: "Storage System Trace Characterization, Compression, and Synthesis using Machine Learning–An Extended Abstract",
      createdAt: "2023-06-01T16:26:54Z",
      description: "Extended Abstract Paper presented in the  SIGSIM-PADS as a part of Federated Computing Research Conference (FCRC 23) ",
      url:
        "https://dl.acm.org/doi/10.1145/3573900.3593632",
    },
    
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "prat.png",
    description:
      "You can contact me at my email: ppoud001@fiu.edu if you have any queries. Scroll if you wanna check out my blog ⤓ or check my YT where I complete my bucketlist of adventure sports.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "Check out my blog: It's a WIP but I try to keep it simple and share my utility based automation works there.",
    link: "./blog",
    avatar_image_path: "blogs_image.svg",
  },
  TrimTimelineSupportSection: {
    title: "Trim Timeline Support",
    subtitle:
      "Support for Trim Timeline app",
    link: "./trimtimelinesupport",
    
  },
  addressSection: {
    title: "Address",
    subtitle:
      "",
    locality: "#",
    country: "US",
    region: "#",
    postalCode: "#",
    streetAddress: "#",
    avatar_image_path: "address_image.svg",
    location_map_link: "#",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
